import React from 'react';
import Game from './containers/Game';
import { useGetUser } from '@fingo/lib/hooks';
import { useMutation } from '@apollo/client';
import { GET_USER, UPDATE_USER_GAME_HIGH_SCORE } from '@fingo/lib/graphql'
import { Stack } from '@mui/material'


function FingoGame() {
  const currentUser = useGetUser()
  const [updateUserGameHighScore] = useMutation(UPDATE_USER_GAME_HIGH_SCORE, { refetchQueries: GET_USER })
  const setHighScore = (newScore) => {
    updateUserGameHighScore({ variables: { newScore: newScore } })
  }
  return (
    <Stack height='100%' justifyContent='center'>
      <Game
        boardSize={16}
        playerSize={25}
        highScore={currentUser?.usergamehighscore?.highScore || 0}
        setHighScore={setHighScore}
      />
    </Stack>
  )
}
export default FingoGame;
