/* eslint-disable implicit-arrow-linebreak */
import { validEmail } from '@fingo/lib/helpers';

export const getCurrentCredentialProvider = (selectedCompany) =>
  selectedCompany?.invoiceProviderCredentials?.at(-1)?.credentialProvider
  || selectedCompany?.possibleCredentialProvider;

export const getCurrentSupplierPortal = (selectedCompany) =>
  selectedCompany?.supplierPortalCredentials?.at(-1);

export const getErrorMessage = (formData, accounts) => {
  const { number, name, email, bank, accountType } = formData;
  if (name.value === '') {
    return 'El nombre de la cuenta no puede estar vacío';
  }
  if (number.value === '') {
    return 'El número de la cuenta no puede estar vacío';
  }
  if (bank.value === 'choose') {
    return 'Debes elegir algún banco';
  }
  if (accountType.value === 'choose') {
    return 'Debes elegir algún tipo de cuenta';
  }
  if (!validEmail(email.value)) {
    return 'Ingresa un mail válido';
  }
  for (let i = 0; i < accounts.length; i += 1) {
    const account = accounts[i];
    if (account.bankName === bank.value && account.accountNumber === number.value) {
      return 'Esa cuenta ya está registrada';
    }
  }
  return null;
};

export const encryptedBankAccountNumber = (bankAccountNumber) => `**** ${bankAccountNumber.substr(bankAccountNumber.length - 4, 4)}`;
