/* eslint-disable operator-linebreak */
import BadgeOutlined from '@mui/icons-material/BadgeOutlined';
import Business from '@mui/icons-material/Business';
import AccountBalanceSharp from '@mui/icons-material/AccountBalanceSharp';
import TextSnippetOutlined from '@mui/icons-material/TextSnippetOutlined';

export const ELECTRONIC_INVOICES_DIALOG_TITLE = 'Ingresa tus credenciales del facturador';

export const ELECTRONIC_INVOICES_DIALOG_SUBTITLE =
  'Elige tu facturador y sube tus credenciales para realizar tus cesiones por dentro de la plataforma, y que así no tengas que salir de Fingo.';

export const ELECTRONIC_INVOICES_DIALOG_ICON = BadgeOutlined;

export const SUPPLIER_PLATFORM_DIALOG_TITLE = 'Ingresa tus credenciales del portal de proveedores';

export const SUPPLIER_PLATFORM_DIALOG_SUBTITLE =
  'Sube las credenciales de tus portales de proveedores para obtener el estado de pago de tus facturas al día y descubre todas las nuevas y útiles funcionalidades que tenemos para ti.';

export const SUPPLIER_PLATFORM_DIALOG_ICON = Business;

export const CREDENTIAL_PROVIDER_DIALOG_CREDENTIALS_ALERT = (providerName) => ({
  message: `Ya tenemos credenciales de tu facturador ${providerName}. Puedes subir nuevamente si lo deseas aquí.`,
  severity: 'success',
  show: true,
});

export const CREDENTIAL_PROVIDER_DIALOG_NO_CREDENTIALS_ALERT = {
  message:
    'No tienes credenciales de tu facturador. Súbelas para agilizar aun más tus operaciones.',
  severity: 'error',
  show: true,
};

export const CREDENTIAL_PROVIDER_DIALOG_SUCCESS_UPLOAD_ALERT = {
  message: 'Credenciales ingresadas exitosamente',
  severity: 'success',
  show: true,
};

export const CREDENTIAL_PROVIDER_DIALOG_ERROR_UPLOAD_ALERT = (providerName) => ({
  message: `Las credenciales son incorrectas. Revisa que tu usuario y contraseña sean de ${providerName}.`,
  severity: 'error',
  show: true,
});

export const BANK_ACCOUNTS_DIALOG_TITLE = 'Administra tus cuentas bancarias';

export const BANK_ACCOUNTS_DIALOG_SUBTITLE = `Ingresa tus datos bancarios para que podamos depositarte. Puedes 
      ingresar múltiples cuentas y seleccionar la que más te acomode al momento de girar.`;

export const BANK_ACCOUNTS_DIALOG_ICON = AccountBalanceSharp;

export const BANK_ACCOUNTS_DIALOG_NO_CREDENTIALS_ALERT = {
  message: 'Aun no tienes ninguna cuenta. Debes agregar una cuenta bancaria.',
  severity: 'error',
  show: true,
};

export const BANK_ACCOUNTS_DIALOG_SUCCESS_ALERT = {
  message: 'Cuenta cargada exitosamente',
  severity: 'success',
  show: true,
};

export const BANK_ACCOUNTS_DIALOG_ERROR_ALERT = (message) => ({
  message,
  severity: 'error',
  show: true,
});

export const DIGITAL_CERTIFICATE_DIALOG_TITLE = 'Administra tu certificado digital';

export const DIGITAL_CERTIFICATE_DIALOG_SUBTITLE = `Carga aquí el certificado digital del Representante Legal de tu 
      empresa para que puedas ceder facturas y contar con más seguridad en las operaciones.`;

export const DIGITAL_CERTIFICATE_DIALOG_ICON = TextSnippetOutlined;

export const DIGITAL_CERTIFICATE_DIALOG_ERROR_MESSAGES = [
  'uploaded Digital Certificate is not valid',
  'invalid certificate credentials, try different ones',
];

export const DIGITAL_CRETIFICATE_INITIAL_VALUE = {
  password: '',
  file: null,
};

export const DIGITAL_CERTIFICATE_DIALOG_CREDENTIALS_ALERT = {
  message:
    'Ya tienes un certificado digital en nuestra plataforma, si deseas cambiarlo puedes subir uno nuevo',
  severity: 'success',
  show: true,
};

export const DIGITAL_CERTIFICATE_DIALOG_NO_CREDENTIALS_ALERT = {
  message:
    'Todavía no tienes un certificado digital en nuestra plataforma, o el que tienes está sin su contraseña respectiva.',
  severity: 'error',
  show: true,
};

export const DIGITAL_CERTIFICATE_DIALOG_SUCCESS_UPLOAD_ALERT = {
  message: 'Recibimos tu certificado digital, ya puedes financiar tus facturas',
  severity: 'success',
  show: true,
};

export const DIGITAL_CERTIFICATE_DIALOG_ERROR_UPLOAD_ALERT = {
  message: `Certificado digital o contraseña del certificado incorrecta.
          Por favor verifique que el archivo subido es un certificado válido vigente o
          vuelva a intentar con una contraseña correcta`,
  severity: 'error',
  show: true,
};

export const DIGITAL_CERTIFICATE_DIALOG_UNKNOWN_ERROR_ALERT = {
  message: 'No pudimos subir tu certificado digital',
  severity: 'error',
  show: true,
};
