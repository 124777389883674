import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useAlertMessage, useIsMobile, useSelectedCompany } from '@fingo/lib/hooks';
import { Box, Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BANK_ACCOUNTS_DIALOG_ERROR_ALERT,
  BANK_ACCOUNTS_DIALOG_ICON,
  BANK_ACCOUNTS_DIALOG_NO_CREDENTIALS_ALERT,
  BANK_ACCOUNTS_DIALOG_SUBTITLE,
  BANK_ACCOUNTS_DIALOG_SUCCESS_ALERT,
  BANK_ACCOUNTS_DIALOG_TITLE,
} from '../../constants/credentials';
import { BankAccountCard, BankAccountForm } from './bank-accounts-dialog';

const BankAccountsDialog = ({ open, setOpen }) => {
  const [openBankAccountForm, setOpenBankAccountForm] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState();
  const selectedCompany = useSelectedCompany();
  const isMobile = useIsMobile();
  const [alert, setAlert, clearAlert] = useAlertMessage();

  const bankAccounts = useMemo(
    () => selectedCompany?.bankAccounts?.filter((a) => a?.active) || [],
    [selectedCompany],
  );

  const closeHandler = useCallback(() => {
    clearAlert();
    setSelectedBankAccount(null);
    setOpenBankAccountForm(false);
    setOpen(false);
  }, [clearAlert]);

  const formSubmitHandler = useCallback((newBankAccount) => {
    setSelectedBankAccount(newBankAccount);
    setAlert(BANK_ACCOUNTS_DIALOG_SUCCESS_ALERT);
    setOpenBankAccountForm(false);
  }, []);

  const formCloseHandler = useCallback(() => {
    setSelectedBankAccount(null);
    setOpenBankAccountForm(false);
  }, []);

  const formErrorHandler = useCallback(({ message }) => {
    setAlert(BANK_ACCOUNTS_DIALOG_ERROR_ALERT(message));
  }, []);

  useEffect(() => {
    if (!open || alert.message.length > 0) return;
    if (bankAccounts.length === 0) {
      setAlert(BANK_ACCOUNTS_DIALOG_NO_CREDENTIALS_ALERT);
    }
  }, [open, alert, bankAccounts]);

  return (
    <FingoDialog
      open={open}
      handleClose={closeHandler}
      dialogAlert={alert}
      setDialogAlert={setAlert}
      title={BANK_ACCOUNTS_DIALOG_TITLE}
      subtitle={!isMobile ? BANK_ACCOUNTS_DIALOG_SUBTITLE : null}
      icon={!isMobile ? BANK_ACCOUNTS_DIALOG_ICON : null}
      maxWidth="sm"
      fullWidth
    >
      {openBankAccountForm ? (
        <BankAccountForm
          bankAccounts={bankAccounts}
          selectedBankAccount={selectedBankAccount}
          onClose={formCloseHandler}
          onSubmit={formSubmitHandler}
          onError={formErrorHandler}
        />
      ) : (
        <Box
          sx={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '95%',
          }}
        >
          <Stack spacing={2} sx={{ py: 2, alignItems: 'center' }}>
            {bankAccounts.map((bankAccount) => (
              <BankAccountCard
                key={bankAccount.id}
                bankAccount={bankAccount}
                selectedBankAccount={selectedBankAccount}
                onSelect={(newSelectedBankAccount) => {
                  setSelectedBankAccount(newSelectedBankAccount);
                }}
                onEditHandler={() => setOpenBankAccountForm(true)}
                selectable
              />
            ))}
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setSelectedBankAccount(null);
                setOpenBankAccountForm(true);
              }}
              fullWidth
              sx={{ width: '50%' }}
            >
              {bankAccounts?.length > 0 ? 'Agregar otra cuenta' : 'Agregar una cuenta'}
            </Button>
          </Stack>
        </Box>
      )}
    </FingoDialog>
  );
};

BankAccountsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default BankAccountsDialog;
