import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useAlertMessage, useIsMobile, useSelectedCompany } from '@fingo/lib/hooks';
import {
  DIGITAL_CERTIFICATE_DIALOG_TITLE,
  DIGITAL_CERTIFICATE_DIALOG_SUBTITLE,
  DIGITAL_CERTIFICATE_DIALOG_ICON,
  DIGITAL_CERTIFICATE_DIALOG_CREDENTIALS_ALERT,
  DIGITAL_CERTIFICATE_DIALOG_NO_CREDENTIALS_ALERT,
  DIGITAL_CERTIFICATE_DIALOG_SUCCESS_UPLOAD_ALERT,
  DIGITAL_CERTIFICATE_DIALOG_UNKNOWN_ERROR_ALERT,
  DIGITAL_CERTIFICATE_DIALOG_ERROR_UPLOAD_ALERT,
  DIGITAL_CERTIFICATE_DIALOG_ERROR_MESSAGES,
} from '../../constants/credentials';
import UploadDigitalCertificateForm from './upload-digital-certificate-dialog/UploadDigitalCertificateForm';

const UploadDigitalCertificateDialog = ({ open, setOpen }) => {
  const isMobile = useIsMobile();
  const [alert, setAlert, clearAlert] = useAlertMessage();
  const selectedCompany = useSelectedCompany();

  const { hasDigitalCertificate, hasCertificateCredentials } = selectedCompany || {};

  const hasCredentials = useMemo(
    () => hasDigitalCertificate && hasCertificateCredentials,
    [hasDigitalCertificate, hasCertificateCredentials],
  );

  const closeHandler = useCallback(() => {
    clearAlert();
    setOpen(false);
  }, [clearAlert, setOpen]);

  const submitHandler = useCallback(() => {
    setAlert(DIGITAL_CERTIFICATE_DIALOG_SUCCESS_UPLOAD_ALERT);
  }, [setAlert]);

  const errorHandler = useCallback(
    ({ message }) => {
      if (DIGITAL_CERTIFICATE_DIALOG_ERROR_MESSAGES.includes(message)) {
        setAlert(DIGITAL_CERTIFICATE_DIALOG_ERROR_UPLOAD_ALERT);
      } else {
        setAlert(DIGITAL_CERTIFICATE_DIALOG_UNKNOWN_ERROR_ALERT);
      }
    },
    [setAlert],
  );

  useEffect(() => {
    if (!open) return;
    if (hasCredentials) {
      setAlert(DIGITAL_CERTIFICATE_DIALOG_CREDENTIALS_ALERT);
    } else {
      setAlert(DIGITAL_CERTIFICATE_DIALOG_NO_CREDENTIALS_ALERT);
    }
  }, [hasCredentials, open]);

  return (
    <FingoDialog
      open={open}
      handleClose={closeHandler}
      dialogAlert={alert}
      setDialogAlert={setAlert}
      title={DIGITAL_CERTIFICATE_DIALOG_TITLE}
      subtitle={!isMobile ? DIGITAL_CERTIFICATE_DIALOG_SUBTITLE : null}
      icon={!isMobile ? DIGITAL_CERTIFICATE_DIALOG_ICON : null}
      maxWidth="sm"
      fullWidth
    >
      <UploadDigitalCertificateForm onSubmit={submitHandler} onError={errorHandler} />
    </FingoDialog>
  );
};

UploadDigitalCertificateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default UploadDigitalCertificateDialog;
