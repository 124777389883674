import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useIsMobile, useSelectedCompany } from '@fingo/lib/hooks';
import { UPLOAD_CERTIFICATE } from '@fingo/lib/graphql';
import UploadFileInput from '@fingo/lib/components/inputs/UploadFileInput';
import { Button, CircularProgress, FormControl, TextField, Typography, Stack } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { DIGITAL_CRETIFICATE_INITIAL_VALUE } from '../../../constants/credentials';
import { OrderingCredentialsConfirm } from '../../../../../components/dialogs';

const UploadDigitalCertificateForm = ({ credentialProviderName, onSubmit, onError }) => {
  const isMobile = useIsMobile();
  const [certificate, setCertificate] = useState(DIGITAL_CRETIFICATE_INITIAL_VALUE);
  const [orderingConfirmOpen, setOrderingConfirmOpen] = useState(false);
  const [orderingConfirmShown, setOrderingConfirmShown] = useState(false);
  const selectedCompany = useSelectedCompany();

  const [uploadCertificate, { loading }] = useMutation(UPLOAD_CERTIFICATE, {
    variables: {
      companyId: selectedCompany.id,
      credentialProviderName,
    },
    onCompleted: () => {
      setCertificate(DIGITAL_CRETIFICATE_INITIAL_VALUE);
      onSubmit();
    },
    onError(error) {
      onError(error);
    },
  });

  const handleCertificatePassword = useCallback(
    ({ target: { value } }) => {
      setCertificate((prevState) => ({ ...prevState, password: value }));
    },
    [certificate],
  );

  const handleCertificateFile = useCallback(
    ({ target: { files } }) => {
      setCertificate((prevState) => ({ ...prevState, file: files[0] }));
    },
    [certificate],
  );

  const shouldShowOrderingConfirm = selectedCompany.isOrdering && !orderingConfirmShown;

  const handleAccept = useCallback(() => {
    if (shouldShowOrderingConfirm) {
      setOrderingConfirmOpen(true);
      setOrderingConfirmShown(true);
    } else {
      uploadCertificate({
        variables: {
          certificatePassword: certificate.password,
          digitalCertificate: certificate.file,
          companyId: selectedCompany.id,
          credentialProviderName,
        },
      });
    }
  }, [certificate, shouldShowOrderingConfirm, selectedCompany, credentialProviderName]);

  const disabled = loading || !certificate.file || !certificate.password;

  return (
    <Stack alignItems="center">
      <FormControl fullWidth sx={{ mb: 3 }}>
        <UploadFileInput
          onChange={handleCertificateFile}
          file={certificate.file}
          label="Sube tu certificado digital"
        />
        {certificate.file && (
          <Stack direction="row">
            <CheckCircle color="success" sx={{ mr: 1.5, fontSize: 16 }} />
            <Typography variant="caption">{certificate.file.name}</Typography>
          </Stack>
        )}
      </FormControl>
      <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
        <Typography mb={1}>Ingresa la clave del certificado</Typography>
        <TextField
          onChange={handleCertificatePassword}
          value={certificate.password}
          type="password"
          autoComplete="new-password"
        />
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleAccept}
        disabled={disabled}
        endIcon={loading && <CircularProgress size={20} />}
        id="save-certificate"
        sx={{ width: isMobile ? '100%' : '50%' }}
      >
        Guardar certificado
      </Button>
      <OrderingCredentialsConfirm
        open={orderingConfirmOpen}
        onClose={() => {
          setOrderingConfirmOpen(false);
        }}
        onAccept={() => {
          setOrderingConfirmOpen(false);
          handleAccept();
        }}
      />
    </Stack>
  );
};

UploadDigitalCertificateForm.propTypes = {
  credentialProviderName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

UploadDigitalCertificateForm.defaultProps = {
  credentialProviderName: '',
};

export default UploadDigitalCertificateForm;
