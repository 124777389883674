import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ThemeProvider, Typography } from '@mui/material'
import theme from '@fingo/lib/theme'


// const style = () => ({
//   container: {
//     textAlign: 'center',
//   },
//   info: {
//     display: 'flex',
//     flexFlow: 'row nowrap',
//     justifyContent: 'space-around',
//   },
// });

const GameInfo = ({
  timeElapsed,
  playerScore,
  highScore,
  globalHighScore = 'Loading...',
}) => {
  // const { container, info } = style();
  return (
    <ThemeProvider theme={theme}>
      <Grid container direction='column' textAlign='center'>
        <Typography color='primary' variant='h4'>Use arrows to move.</Typography>
        <Grid item direction='row' container justifyContent='space-around' py={4}>
          <Typography>Time: {timeElapsed}</Typography>
          <Typography>Score: {playerScore}</Typography>
        </Grid>
        <Grid item >
          <Typography>High Score: {highScore}</Typography>
          {/* <p>Global High Score: {globalHighScore}</p> */}
        </Grid>
      </Grid >
    </ThemeProvider >
  );
};

GameInfo.propTypes = {
  timeElapsed: PropTypes.number.isRequired,
  playerScore: PropTypes.number.isRequired,
  highScore: PropTypes.number.isRequired,
  // globalHighScore: PropTypes.number
};

export default GameInfo;
