import React from 'react';
import { FingoDivider } from '@fingo/lib/components/dividers';
import { PaperHeader } from '@fingo/lib/components/headers';
import { Grid, Stack, Typography } from '@mui/material';
import CredentialsCard from '../components/credentials/CredentialsCard';
import useCompanyCredentials from '../../../hooks/useCompanyCredentials';

const Credentials = () => {
  const companyCredentials = useCompanyCredentials().filter((cred) => cred.showInCredentials);
  return (
    <Stack spacing={1} py={3} px={1}>
      <PaperHeader viewTitle="Mis credenciales" />
      <FingoDivider />
      <Typography mt="16px !important">
        Porque te queremos entregar la experiencia Fingo completa,{' '}
        <strong>ingresa tus credenciales</strong> y acelerarás tus procesos en nuestra plataforma.
      </Typography>
      <Typography mb="16px !important" mt="4px !important">
        Recuerda que las <strong>claves en Fingo están encriptadas</strong>, por lo que están
        seguras con nosotros.
      </Typography>
      <Grid container spacing={2}>
        {companyCredentials.map((document) => (
          <Grid key={document.title} item xs={12} md={6}>
            <CredentialsCard {...document} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default Credentials;
