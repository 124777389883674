import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIsMobile, useSelectedCompany, useAlertMessage } from '@fingo/lib/hooks';
import { useQuery } from '@apollo/client';
import { GET_INVOICE_PROVIDER } from '@fingo/lib/graphql';
import { Skeleton } from '@mui/material';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { SUPPLIER_PLATFORM_TYPE } from '@fingo/lib/constants';
import {
  CredentialProviderSelector,
  CredentialProviderAuthForm,
} from './credential-provider-dialog';
import { getCurrentSupplierPortal } from '../../helpers/credentials';
import {
  CREDENTIAL_PROVIDER_DIALOG_CREDENTIALS_ALERT,
  CREDENTIAL_PROVIDER_DIALOG_ERROR_UPLOAD_ALERT,
  CREDENTIAL_PROVIDER_DIALOG_NO_CREDENTIALS_ALERT,
  CREDENTIAL_PROVIDER_DIALOG_SUCCESS_UPLOAD_ALERT,
  SUPPLIER_PLATFORM_DIALOG_ICON,
  SUPPLIER_PLATFORM_DIALOG_SUBTITLE,
  SUPPLIER_PLATFORM_DIALOG_TITLE,
} from '../../constants/credentials';

const SupplierPortalDialog = ({ open, setOpen }) => {
  const isMobile = useIsMobile();
  const selectedCompany = useSelectedCompany();
  const [selectedProvider, setSelectedProvider] = useState();
  const [alert, setAlert, clearAlert] = useAlertMessage();

  const providerClickHandler = useCallback((newProvider) => {
    setSelectedProvider(newProvider);
  }, []);

  const cancelHandler = useCallback(() => {
    setSelectedProvider(null);
  }, []);

  const closeHandler = useCallback(() => {
    clearAlert();
    cancelHandler();
    setOpen(false);
  }, []);

  const submitHandler = useCallback(() => {
    setAlert(CREDENTIAL_PROVIDER_DIALOG_SUCCESS_UPLOAD_ALERT);
  }, []);

  const errorHandler = useCallback(() => {
    setAlert(CREDENTIAL_PROVIDER_DIALOG_ERROR_UPLOAD_ALERT);
  }, []);

  const { data, loading } = useQuery(GET_INVOICE_PROVIDER, {
    variables: { credentialProviderType_Name: SUPPLIER_PLATFORM_TYPE },
  });

  const providers = useMemo(() => Object.values(data || [])?.[0] || [], [data]);
  const integratedProviders = useMemo(
    () => providers.filter(({ integrated }) => integrated),
    [providers],
  );
  const notIntegratedProviders = useMemo(
    () => providers.filter(({ integrated }) => !integrated),
    [providers],
  );
  const currentProvider = useMemo(
    () => getCurrentSupplierPortal(selectedCompany),
    [selectedCompany],
  );

  useEffect(() => {
    if (!open || loading) return;
    if (currentProvider && selectedCompany.hasInvoiceProviderCredentials) {
      setAlert(CREDENTIAL_PROVIDER_DIALOG_CREDENTIALS_ALERT(currentProvider.name));
    } else {
      setAlert(CREDENTIAL_PROVIDER_DIALOG_NO_CREDENTIALS_ALERT);
    }
  }, [currentProvider, open, loading]);

  if (loading) return <Skeleton />;

  return (
    <FingoDialog
      open={open}
      handleClose={closeHandler}
      dialogAlert={alert}
      setDialogAlert={setAlert}
      title={SUPPLIER_PLATFORM_DIALOG_TITLE}
      subtitle={!isMobile ? SUPPLIER_PLATFORM_DIALOG_SUBTITLE : null}
      icon={!isMobile ? SUPPLIER_PLATFORM_DIALOG_ICON : null}
      maxWidth="sm"
      fullWidth
    >
      {!selectedProvider && (
        <CredentialProviderSelector
          integratedProviders={integratedProviders}
          notIntegratedProviders={notIntegratedProviders}
          currentProvider={currentProvider}
          onProviderClick={providerClickHandler}
          buttonLabel="Otra plataforma"
          listSubheaderLabel="Portal de proveedores"
        />
      )}
      {selectedProvider && (
        <CredentialProviderAuthForm
          selectedProvider={selectedProvider}
          authenticationType="username"
          onCancel={cancelHandler}
          onSubmit={submitHandler}
          onError={errorHandler}
          showGoBack
        />
      )}
    </FingoDialog>
  );
};

SupplierPortalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default SupplierPortalDialog;
