/* eslint-disable operator-linebreak */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAlertMessage, useIsMobile, useSelectedCompany } from '@fingo/lib/hooks';
import { Skeleton } from '@mui/material';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import {
  CredentialProviderSelector,
  CredentialProviderAuthMethodSelector,
  CredentialProviderAuthForm,
} from './credential-provider-dialog';
import {
  ELECTRONIC_INVOICES_DIALOG_ICON,
  ELECTRONIC_INVOICES_DIALOG_TITLE,
  ELECTRONIC_INVOICES_DIALOG_SUBTITLE,
  CREDENTIAL_PROVIDER_DIALOG_SUCCESS_UPLOAD_ALERT,
  CREDENTIAL_PROVIDER_DIALOG_ERROR_UPLOAD_ALERT,
  CREDENTIAL_PROVIDER_DIALOG_NO_CREDENTIALS_ALERT,
  CREDENTIAL_PROVIDER_DIALOG_CREDENTIALS_ALERT,
} from '../../constants/credentials';
import useGetInvoiceProviders from '../../../../hooks/useGetInvoiceProviders';

const CredentialProviderDialog = ({ open, setOpen }) => {
  const isMobile = useIsMobile();
  const selectedCompany = useSelectedCompany();
  const [selectedProvider, setSelectedProvider] = useState();
  const [authenticationType, setAuthenticationType] = useState();
  const [alert, setAlert, clearAlert] = useAlertMessage();

  const providerClickHandler = useCallback((newProvider) => {
    setSelectedProvider(newProvider);
    if (newProvider.name !== 'MiPyme') {
      setAuthenticationType('username');
    }
  }, []);

  const authMethodClickHandler = useCallback((authMethod) => {
    setAuthenticationType(authMethod);
  }, []);

  const cancelHandler = useCallback(() => {
    setSelectedProvider(null);
    setAuthenticationType(null);
  }, []);

  const closeHandler = useCallback(() => {
    clearAlert();
    cancelHandler();
    setOpen(false);
  }, []);

  const submitHandler = useCallback(() => {
    setAlert(CREDENTIAL_PROVIDER_DIALOG_SUCCESS_UPLOAD_ALERT);
    closeHandler();
  }, []);

  const errorHandler = useCallback(() => {
    setAlert(CREDENTIAL_PROVIDER_DIALOG_ERROR_UPLOAD_ALERT(selectedProvider?.name));
  }, [selectedProvider]);

  const { integratedProviders, notIntegratedProviders, currentProvider,
    loading } = useGetInvoiceProviders();

  const showCredentialProviderAuthMethodSelector =
  selectedProvider?.name === 'MiPyme' && !authenticationType;
  const showCredentialProviderAuthForm = selectedProvider && authenticationType;

  useEffect(() => {
    if (!open || loading) return;
    if (currentProvider && selectedCompany.hasInvoiceProviderCredentials) {
      setAlert(CREDENTIAL_PROVIDER_DIALOG_CREDENTIALS_ALERT(currentProvider.name));
    } else {
      setAlert(CREDENTIAL_PROVIDER_DIALOG_NO_CREDENTIALS_ALERT);
    }
  }, [currentProvider, open, loading]);

  if (loading) return <Skeleton />;

  return (
    <FingoDialog
      open={open}
      handleClose={closeHandler}
      dialogAlert={alert}
      setDialogAlert={setAlert}
      title={ELECTRONIC_INVOICES_DIALOG_TITLE}
      subtitle={!isMobile ? ELECTRONIC_INVOICES_DIALOG_SUBTITLE : null}
      icon={!isMobile ? ELECTRONIC_INVOICES_DIALOG_ICON : null}
      maxWidth="sm"
      fullWidth
    >
      {!selectedProvider && (
        <CredentialProviderSelector
          integratedProviders={integratedProviders}
          notIntegratedProviders={notIntegratedProviders}
          currentProvider={currentProvider}
          onProviderClick={providerClickHandler}
          buttonLabel="Otro facturador"
          listSubheaderLabel="Facturador"
          showHelpLink
        />
      )}
      {showCredentialProviderAuthMethodSelector && (
        <CredentialProviderAuthMethodSelector
          selectedProvider={selectedProvider}
          onCancel={cancelHandler}
          onClick={authMethodClickHandler}
        />
      )}
      {showCredentialProviderAuthForm && (
        <CredentialProviderAuthForm
          selectedProvider={selectedProvider}
          authenticationType={authenticationType}
          onCancel={cancelHandler}
          onSubmit={submitHandler}
          onError={errorHandler}
        />
      )}
    </FingoDialog>
  );
};

CredentialProviderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default CredentialProviderDialog;
